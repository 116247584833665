@import "../bower_components/normalize.css/normalize";
@import "bootstrap/bootstrap";

$color1: #F3E03A;

@font-face {
  font-family: BlenderPro;
  src: url("/fonts/BlenderPro-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: BlenderPro;
  src: url("/fonts/BlenderPro-Book.ttf");
  font-weight: 300;
}
@font-face {
  font-family: BlenderPro;
  src: url("/fonts/BlenderPro-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: BlenderPro;
  src: url("/fonts/BlenderPro-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: BlenderPro;
  src: url("/fonts/BlenderPro-Heavy.ttf");
  font-weight: 800;
}



@font-face {
  font-family: SFPro;
  src: url("/fonts/SFProDisplay-Ultralight.ttf");
  font-weight: 100;
}
@font-face {
  font-family: SFPro;
  src: url("/fonts/SFProDisplay-Thin.ttf");
  font-weight: 200;
}
@font-face {
  font-family: SFPro;
  src: url("/fonts/SFProDisplay-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: SFPro;
  src: url("/fonts/SFProDisplay-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: SFPro;
  src: url("/fonts/SFProDisplay-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: SFPro;
  src: url("/fonts/SFProDisplay-Semibold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: SFPro;
  src: url("/fonts/SFProDisplay-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: SFPro;
  src: url("/fonts/SFProDisplay-Heavy.ttf");
  font-weight: 800;
}
@font-face {
  font-family: SFPro;
  src: url("/fonts/SFProDisplay-Black.ttf");
  font-weight: 900;
}

*{
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 400;
  font-family: 'SFPro';
}
body{
  background: $color1;
}
.container{
  position: relative;
  max-width: 1650px;
}
section{
  position: relative;
  overflow: hidden;
  .container{
    top: 50%;
    transform: translate(0, -50%);
    padding-right: 17%;
    position: relative;
    z-index: 2;
  }
}
.container-fluid{
  position: relative;
}
a{
  text-decoration: none;
}
input,button,textarea{
  &:focus{
    outline: none;
  }
}
a,button{
  &:hover{
    cursor: pointer;
    text-decoration: none;
    color: #fff;
  }
}
body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  background: #ccc;
}
body::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: $color1;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.6);
}
.fatherBlock{
  position: relative;
  width: 100%;
  overflow: hidden;
}
a, button{
  transition: .2s linear;
  &:hover{
    cursor: pointer;
    opacity: 0.6;
  }
}
h1,h2,h3,h4,.h3{
  font-family: 'BlenderPro';
  display: table;
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  color: #fff;
  margin: 0 0 20px;
  text-transform: uppercase;
  letter-spacing: -1px;
  line-height: 1;
  span{
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    color: $color1;
    text-transform: uppercase;
    font-family: 'BlenderPro';
    text-transform: uppercase;
    letter-spacing: -1px;
    line-height: 0.8;
  }
}
p.pre{
  display: table;
  font-family: 'BlenderPro';
  display: table;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  margin: 0 0 20px;
  border-left: 2px solid $color1;
  padding-left: 15px;
  line-height: 1;
  color: #fff;
}
p.descr{
  display: table;
  margin: 0 0 48px;
  line-height: 1.6;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  //color: #1B1B1B;
  //opacity: 0.9;
}

.whiteStyle, .yellowStyle{
  h1,h2,h3,h4,p.pre,p.descr{
    color: #1b1b1b;
  }
  p.descr{
    opacity: 0.9;
  }
}

.defaultButton{
  display: inline-table;
  vertical-align: middle;
  background: $color1;
  position: relative;
  border: none;
  border-radius: 0 10px 0 10px;
  clip-path: polygon(0% 14.2px, 14.2px 0%, 100% 0%, 100% 14.2px, 100% calc(100% - 14.2px), calc(100% - 14.2px) 100%, 14.2px 100%, 0% 100%);
  padding: 11px 20px;
  color: #1b1b1b;
  font-size: 20px;
  font-family: 'BlenderPro';
  font-weight: 800;
  text-transform: uppercase;
  min-width: 250px;
  &.black{
    color: $color1;
    background: #1b1b1b;
  }
  &+.arrows{
    margin-left: 25px;
  }
}

button.arrows{
  display: inline-table;
  vertical-align: middle;
  transition: unset;
  margin: 0;
  background: $color1;
  position: relative;
  border: none;
  border-radius: 0 10px 0 10px;
  clip-path: polygon(0% 14.2px, 14.2px 0%, 100% 0%, 100% 14.2px, 100% calc(100% - 14.2px), calc(100% - 14.2px) 100%, 14.2px 100%, 0% 100%);
  padding: 12px;
  &:before{
    content: '';
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #1b1b1b;
    clip-path: polygon(0% 13.2px, 13.2px 0%, 100% 0%, 100% 13.2px, 100% calc(100% - 13.2px), calc(100% - 13.2px) 100%, 13.2px 100%, 0% 100%);
    border-radius: 0 8px 0 8px;
  }
  &.yellowStyle{
    background: #1b1b1b;
    img:first-child{
      display: none;
    }
    &:before{
      background: $color1;
    }
    &:hover{
      opacity: 1;
      &:before{
        background: #1b1b1b;
      }
      img:first-child{
        display: table;
      }
      img:last-child{
        display: none;
      }
    }
  }
  img{
    display: table;
    height: 24px;
    position: relative;
    z-index: 2;
  }

  &+.arrows{
    margin-left: 10px;
  }
  &.left{
    img{
      transform: rotate(180deg);
    }
  }
}

.loader{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: $color1;
  z-index: 50;
  transition: .5s linear;
  img{
    position: absolute;
    max-width: 40%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.transitionStyle{
    opacity: 0;
  }
}

#controlBlockAbsolute{
  position: fixed;
  display: table;
  top: 0;
  right: 50%;
  transform: translate(825px, 0);
  height: 100vh;
  z-index: 10;
  width: 300px;
  &:after{
    content: '';
    position: absolute;
    right: calc(15px + 4px);
    top: 0;
    height: 100%;
    width: 1px;
    background: #1b1b1b;
    opacity: .1;
  }
    ul{
      z-index: 10;
    }
    ul.numbers{
      position: absolute;
      top: 50px;
      right: 15px;
      display: table;
      text-align: right;
      width: 100%;
      padding-right: 25px;
      &:after{
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 9px;
        height: 9px;
        background: #1b1b1b;
        border-radius: 50%;
        border: none;
      }
      li{
        display: table;
        margin: 0;
        text-align: right;
        width: 100%;
        a{
          display: table;
          text-align: right;
          width: 100%;
          font-size: 24px;
          font-weight: 700;
          color: #1b1b1b;
          span{
            font-size: 20px;
            font-weight: 600;
            opacity: 0.5;
            margin: 0 5px 0 0;
          }
        }
      }
    }
    ul.menu{
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translate(0, -50%);
      display: table;
      text-align: right;
      width: 100%;
      margin: 0;
      li{
        display: table;
        text-align: right;
        width: 100%;
        margin: 0;
        &+li{
          margin-top: 24px;
        }
        a{
          display: table;
          text-align: right;
          width: 100%;
          font-size: 24px;
          color: #1b1b1b;
          opacity: 0.5;
          font-family: 'BlenderPro';
          font-weight: 800;
          text-transform: uppercase;
          padding-right: 25px;
          position: relative;
          &:after{
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            width: 9px;
            height: 9px;
            background: #1b1b1b;
            border-radius: 50%;
            border: none;
          }
          &.active{
            opacity: 1;
          }
        }
      }
    }
    ul.social{
      position: absolute;
      right: 15px;
      bottom: 100px;
      display: table;
      padding-right: 25px;
      margin: 0;
      &:after{
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 9px;
        height: 9px;
        background: #1b1b1b;
        border-radius: 50%;
        border: none;
      }
      li{
        display: inline-table;
        vertical-align: middle;
        margin: 0;
        &+li{
          margin-left: 15px;
        }
        a{
          display: table;
          margin: 0;
          i{
            font-size: 24px;
            color: #1b1b1b;
          }
        }
      }
    }
  &.block_name{
      &.company{
        &:after{
          background: #BDAF32;
        }
        ul.menu{
          a{
            color: #BDAF32;
            &.active{
              color: #1b1b1b;
              &:after{
                background: #1b1b1b;
              }
            }
            &:after{
              background: #BDAF32;
            }
          }
        }
        ul.social{
          a{
            i{
              color: $color1;
            }
          }
          &:after{
            background: $color1;
          }
        }
      }
      &.about,&.partners,&.products, &.cements,&.projects{
        &:after{
          background: #BDAF32;
        }
        ul.numbers{
          a{
            color: $color1;
          }
          &:after{
            background: $color1;
          }
        }
        ul.menu{
          a{
            color: #BDAF32;
            &.active{
              color: $color1;
            }
            &:after{
              background: #BDAF32;
            }
          }
        }
        ul.social{
          &:after{
            background: $color1;
          }
          a{
            i{
              color: $color1;
            }
          }
        }
      }
      &.news{

      }
      &.contacts{

      }
  }
}

section{
  &#home_section{
    background: $color1;
    .boldLineVertical{
      position: absolute;
      z-index: 0;
      left: 50%;
      top: -100%;
      width: 540px;
      height: 100%;
      background: #1b1b1b;
      opacity: .05;
      transform: translate(-50%, 0);
      transition: 1.5s ease-in-out;
    }
    .container{
      position: relative;
      height: 100%;
      padding-top: 50px;
      img.absolBkg{
        position: absolute;
        height: calc(100% + 300px);
        width: 3000px;
        object-fit: cover;
        //object-position: left top;
        object-position: -500px -500px;
        transition: 1.5s ease-in-out;
        left: -334px;
        top: -299px;
        z-index: 2;
      }
      img.logo{
        display: table;
        margin: 0;
        position: relative;
        z-index: 3;
      }
    }
    .homeSlick{
      width: 90%;
      position: absolute;
      right: 17%;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 1;
      .slick-slide{
        .infoSlide{
          display: block;
          width: 100%;
          height: 700px;
          margin-left: 1800px;
          transition: 1.5s ease-in-out;
          img{
            display: table;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center right;
          }
        }
      }
    }
    .bottomBlock{
      position: absolute;
      bottom: 100px;
      left: 15px;
      z-index: 3;
      width: 35%;
      opacity: 0;
      transition: 1s linear;
      .homeSlickSecond{
        display: block;
        width: 100%;
        transition: 1s linear;
        .infoBlock{
          p.mini{
            font-size: 24px;
            font-family: 'BlenderPro';
            font-weight: 700;
            text-transform: uppercase;
            color: #fff;
            opacity: 0.5;
            margin: 0 0 40px;
          }
        }
      }
    }
    &.animation{
      .boldLineVertical{
        top: 0;
      }
      img.absolBkg{
        object-position: 0 0;
      }
      .homeSlick{
        .slick-slide{
          .infoSlide {
            margin-left: 0;
          }
        }
      }
    }
    &.afterAnimation{
      .homeSlick{
        transition: .2s linear;
        .slick-slide{
          .infoSlide {
            transition: .3s linear;
          }
        }
      }
      .bottomBlock{
        opacity: 1;
        .homeSlickSecond{
          transition: .3s linear;
        }
      }
    }
    &.sliderSwiped{
      .homeSlick{
        opacity: 0;
        .slick-slide{
          .infoSlide {
            margin-left: 1800px;
            opacity: 0;
          }
        }
      }
      .bottomBlock{
        .homeSlickSecond{
          margin-left: -1000px;
        }
      }
    }
  }
  &#company_section{
    background: $color1;
    .container{
      position: relative;
      height: 100%;
      img.absolBkg{
        position: absolute;
        height: calc(100% + 300px);
        width: 3000px;
        object-fit: cover;
        object-position: left -100vh;
        left: -334px;
        top: -299px;
        z-index: 0;
      }
    }
    .row{
      position: relative;
      z-index: 1;
      top: 50%;
      transform: translate(0, -50%);
    }
    .videoBlock{
      display: block;
      position: relative;
      width: 100%;
      margin: 0;
      img.bkg{
        display: table;
        width: 100%;
        object-fit: contain;
      }
      button.play{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%,);
        overflow: hidden;
        border-radius: 50%;
        border: none;
        background: transparent;
        img{
          display: table;
          height: 100px;
        }
      }
    }
  }
  &#about_section{
    position: relative;
    background: $color1;
    z-index: 2;
    .container{
      height: 100%;
      position: relative;
      .row{
        position: relative;
        z-index: 1;
        top: 50%;
        transform: translate(0, -50%);
      }
      img.absolBkg{
        position: absolute;
        height: calc(300% + 300px);
        width: 3000px;
        object-fit: cover;
        object-position: left -200vh;
        left: -334px;
        top: -299px;
        z-index: 0;
      }
    }
  }
  &.about{
    img.icon{
      display: inline-table;
      margin: 0;
      vertical-align: top;
      width: 80px;
    }
    p.text{
      display: table;
      margin: 0 0 30px;
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      line-height: 1.6;
      b{
        display: table;
        font-size: 40px;
        font-weight: 700;
        color: $color1;
      }
      span{
        display: table;
        font-size: 28px;
        font-weight: 800;
        margin-bottom: 3px;
      }
      &.inline{
        display: inline-table;
        vertical-align: top;
        max-width: calc(100% - 115px);
        margin-left: 30px;
      }
    }
  }
  &#certs_section{
    .certSlick{
      width: 100%;
      margin: 40px 0 50px;
      .slick-slide{
        button{
          display: table;
          width: 90%;
          background: transparent;
          border: 2px solid #ccc;
          img{
            display: table;
            width: 100%;
            height: 360px;
            object-fit: cover;
            object-position: center;
          }
          &:hover{
            border-color: $color1;
            opacity: 1;
          }
        }
      }
    }
  }
  &#partners_section{
    ul.partnersList{
      display: flex;
      width: 100%;
      margin: 60px 0;
      li{
        display: inline-flex;
        vertical-align: middle;
        margin: 0;
        flex-grow: 1;
        width: 25%;
        img{
          max-width: 65%;
          max-height: 70px;
          object-fit: contain;
          object-position: left center;
        }
      }
    }
  }
  &.productsSection{
    .customProdutsSlider{
      width: 100%;
      margin: 0 0 30px;
      .slick-slide{
        width: 100%;
        img.productImg{
          display: table;
          width: 100%;
          max-height: 500px;
          object-fit: contain;
          object-position: left center;
        }
        h3{
          margin-bottom: 30px;
        }
        p.afterText{
          display: table;
          margin: 0 0 30px;
          font-size: 28px;
          font-weight: 800;
          color: $color1;
          text-transform: uppercase;
        }
        .infoBlock{
          display: block;
          width: 100%;
          padding-left: 40px;
          p.name{
            display: table;
            font-size: 28px;
            font-weight: 700;
            color: #fff;
            margin: 0 0 15px;
            position: relative;
            span{
              color: $color1;
              font-size: 28px;
              font-weight: 700;
            }
            padding-bottom: 15px;
            &:after{
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 50px;
              height: 2px;
              background: #fff;
              opacity: 0.1;
            }
          }
          .bodyText{
            display: block;
            margin: 0;
            padding: 0;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.6;
            color: #fff;
            *{
              font-size: 20px;
              font-weight: 400;
              line-height: 1.6;
              color: #fff;
              margin: 0 0 15px;
              padding: 0;
            }
          }
          p.descr, p.textP{
            display: table;
            margin: 0 0 30px;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.6;
            color: #fff;
          }
          p.textP{
            display: inline-table;
            span{
              color: $color1;
              font-size: 24px;
              font-weight: 700;
              line-height: 1.6;
              display: table;
            }
            &+p.textP{
              margin-left: 45px;
            }
            &.full{
              margin-left: 0!important;
            }
          }
          p.yesText{
            display: inline-table;
            width: 49%;
            margin: 0 0 30px;
            img{
              display: inline-table;
              vertical-align: middle;
              margin: 0;
              width: 50px;
            }
            span{
              display: inline-table;
              vertical-align: middle;
              width: calc(100% - 76px);
              margin-left: 25px;
              color: #fff;
              font-size: 20px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  &#projects_section{
    .projectsSlick{
      width: 120%;
      margin: 50px 0;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
        background: linear-gradient(90deg,rgba(0,0,0,.0397409) 0,#1b1b1b 90%);
      }
      .slick-slide{
        .block{
          display: block;
          width: 95%;
          height: 400px;
          position: relative;
          border-radius: 0 40px 0 40px;
          overflow: hidden;
          background: $color1;
          clip-path: polygon(0% 59.2px, 59.2px 0%, 100% 0%, 100% 59.2px, 100% calc(100% - 59.2px), calc(100% - 59.2px) 100%, 59.2px 100%, 0% 100%);
          .inBlock{
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            border-radius: 0 40px 0 40px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            position: relative;
            display: block;
            overflow: hidden;
            clip-path: polygon(0% 59.2px, 59.2px 0%, 100% 0%, 100% 59.2px, 100% calc(100% - 59.2px), calc(100% - 59.2px) 100%, 59.2px 100%, 0% 100%);
          }
          img{
            display: table;
            width: 100%;
            height: 100%;
          }
          p.title{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            color: #fff;
            font-size: 24px;
            font-weight: 800;
            padding: 20px 30px;
            text-transform: uppercase;
            letter-spacing: 1px;
            z-index: 2;
          }
          .overflow{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(180deg,rgba(0,0,0,.0397409) 0,rgba(0,0,0,.32) 100%);
          }
        }
      }
    }
  }
  &#news_section{
    .newsSlick{
      margin: 40px 0 60px;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        width: 90%;
        left: 0;
        bottom: 56px;
        height: 1px;
        background: #1b1b1b;
      }
      .slick-slide{
        .block{
          display: block;
          width: 80%;
          position: relative;
          margin: 0;
          img{
            display: block;
            position: relative;
            width: 100%;
            height: 280px;
            object-fit: cover;
            object-position: center;
            margin: 0 0 30px;
            border-radius: 0 40px 0 40px;
            clip-path: polygon(0% 59.2px, 59.2px 0%, 100% 0%, 100% 59.2px, 100% calc(100% - 59.2px), calc(100% - 59.2px) 100%, 59.2px 100%, 0% 100%);
          }
          p.name{
            display: table;
            font-size: 28px;
            font-weight: 700;
            text-transform: uppercase;
            position: relative;
            padding-bottom: 15px;
            margin-bottom: 15px;
            &:after{
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 50px;
              height: 2px;
              background: #000;
              opacity: 0.1;
            }
          }
          p.descr{
            font-size: 20px;
            line-height: 1.5;
            opacity: 1;
            margin: 0;
          }
          p.date{
            display: table;
            margin: 25px 0 0;
            position: relative;
            padding-top: 25px;
            font-family: 'BlenderPro';
            font-weight: 800;
            font-size: 24px;
            text-transform: uppercase;
            &:before{
              content: '';
              position: absolute;
              top: -8px;
              left: 40px;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background: #1b1b1b;
            }
          }
        }
      }
    }
  }
  &#contacts_section{
    h3{
      margin-bottom: 60px;
    }
    p.text{
      display: table;
      margin: 40px 0 0;
      width: 80%;
      img{
        display: inline-table;
        vertical-align: top;
        width: 50px;
        margin: 0 24px 0 0;
      }
      span{
        display: inline-table;
        vertical-align: top;
        width: calc(100% - 75px);
        font-size: 24px;
        font-weight: 500;
        line-height: 1.4;
        margin: -7px 0 0;
      }
      &.kast{
        img{
          vertical-align: middle;
        }
        span{
          vertical-align: middle;
          margin: 0;
        }
      }
    }
    .defaultButton{
      margin-top: 50px;
      padding: 16px 25px;
      font-size: 24px;
    }
    .map{
      position: absolute;
      left: 40%;
      top: 0;
      height: 100%;
      width: 550px;
      background: #1b1b1b;
      padding: 0 15px;
      &:before{
        content: '';
        position: absolute;
        left: 15px;
        top: 0;
        width: calc(100% - 30px);
        height: 100%;
        background: #ccc;
      }
    }
  }
  &.whiteStyle{
    background: url("/img/bkg2.jpg")no-repeat;
    background-size: cover;
    background-position: center;
    *{
      color: #1b1b1b!important;
    }
    p.descr{
      font-weight: 500;
    }
    p.text{
      font-weight: 500;
    }

    button.arrows{
      background: #1b1b1b;
      &:before{
        background: #fff;
      }
      &:hover{
        background: $color1;
        &:before{
          background: $color1;
        }
        opacity: 1;
      }
    }
  }
  &.blackStyle{
    background: url("/img/bkg3.jpg")no-repeat;
    background-size: cover;
    background-position: center;
  }
  &.yellowStyle{
    background: url("/img/bkg4.png")no-repeat;
    background-size: cover;
    background-position: center;
    p.pre{
      border-color: #1b1b1b;
    }
  }
}

@media (max-width: 1600px) and (min-width: 679px) {
  #controlBlockAbsolute {
    transform: translate(675px, 0);
    ul.numbers{
      li{
        a{
          font-size: 20px;
          span{
            font-size: 16px;
          }
        }
      }
    }
    ul.menu{
      li{
        a{
          font-size: 20px;
        }
      }
    }
    ul.social{
      bottom: 50px;
      li{
        a{
          font-size: 20px;
        }
      }
    }
  }
  .container{
    max-width: 1350px;
  }
  p.pre{
    font-size: 24px;
  }
  h1,h2,h3,h4,.h3{
    font-size: 62px;
    span{
      font-size: 62px;
    }
  }
  p.descr{
    font-size: 20px;
  }
  section{
    .container{
      padding-right: 20%;
    }

    &#home_section{
      .boldLineVertical{
        width: 420px;
      }
      .homeSlick{
        right: 20%;
        .slick-slide{
          .infoSlide{
            height: 500px;
          }
        }
      }
      .bottomBlock{
        bottom: 60px;
        width: 30%;
        .homeSlickSecond{
          .infoBlock{
            p.pre{
              font-size: 24px;
            }
            .h3{
              font-size: 58px;
            }
            p.mini{
              font-size: 20px;
              margin: 0 0 20px;
            }
          }
        }
      }
    }
    &#company_section{
      .videoBlock{
        max-width: 80%;
        width: auto;
        display: table;
        img.bkg{
          width: auto;
          max-height: 300px;
        }
      }
    }
    &.about{
      p.text{
        font-size: 18px;
        b{
          font-size: 30px;
        }
        span{
          font-size: 22px;
        }
      }
    }
    &#certs_section{
      .certSlick{
        .slick-slide{
          button{
            img{
              height: 300px;
            }
          }
        }
      }
    }
    &#partners_section{
      ul.partnersList{
        li{
          img{
            max-width: 65%;
            max-height: 60px;
          }
        }
      }
    }
    &.productsSection{
      .customProdutsSlider{
        .slick-slide{
          img.productImg{
            max-height: 380px;
          }
          p.afterText{
            font-size: 18px;
            margin-bottom: 15px;
          }
          .infoBlock{
            padding-left: 30px;
            p.name{
              font-size: 18px;
              span{
                font-size: 18px;
              }
            }
            p.descr, p.textP{
              font-size: 16px;
              margin-bottom: 15px;
            }
            p.textP{
              span{
                font-size: 16px;
              }
            }
            .bodyText{
              display: block;
              margin: 0;
              padding: 0;
              font-size: 16px;
              font-weight: 400;
              line-height: 1.6;
              color: #fff;
              max-width: 100%;
              *{
                font-size: 16px;
                font-weight: 400;
                line-height: 1.6;
                color: #fff;
                margin: 0 0 10px;
                padding: 0;
                display: table;
                max-width: 100%;
              }
              img{
                max-width: 100%;
                max-height: 100px;
              }
            }
            p.yesText{
              margin-bottom: 14px;
              img{
                width: 40px;
              }
              span{
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    &#projects_section{
      .projectsSlick{
        .slick-slide{
          .block{
            height: 300px;
            p.title{
              font-size: 18px;
            }
          }
        }
      }
    }
    &#news_section {
      .newsSlick {
        margin: 20px 0 30px;
        &:after{
          bottom: 50px;
        }
        .slick-slide{
          .block{
            img{
              height: 200px;
            }
            p.name{
              font-size: 20px;
              padding-bottom: 10px;
              margin-bottom: 10px;
            }
            p.descr{
              font-size: 18px;
              line-height: 1.4;
            }
            p.date{
              font-size: 20px;
            }
          }
        }
      }
    }

    &#contacts_section{
      h3{
        margin-bottom: 50px;
      }
      p.text{
        display: table;
        margin: 30px 0 0;
        width: 80%;
        img{
          display: inline-table;
          vertical-align: top;
          width: 50px;
          margin: 0 24px 0 0;
        }
        span{
          display: inline-table;
          vertical-align: top;
          width: calc(100% - 75px);
          font-size: 18px;
          font-weight: 500;
          line-height: 1.4;
          margin: -7px 0 0;
        }
      }
      .defaultButton{
        margin-top: 50px;
        padding: 14px 25px;
        font-size: 20px;
      }
    }

  }
}

@media (max-width: 678px) {
  div#mobileHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #f3e03a;
    z-index: 50;
    padding: 10px;
  }

  div#mobileHeader img.logo {
    display: table;
    margin: 0;
    height: 40px;
  }

  div#mobileHeader button.burger {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    background: transparent;
    border: none;
    opacity: 1;
  }

  div#mobileHeader ul.menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 20px 10px;
    background: #f3e03a;
    border-top: 1px solid #fff;
    margin: 0;
    display: none;
    &.opened{
      display: block;
    }
  }

  div#mobileHeader button.burger img {
    display: table;
    height: 30px;
  }

  div#mobileHeader ul.menu li {
    display: table;
    width: 100%;
    margin: 0;
  }

  div#mobileHeader ul.menu li a {
    font-size: 16px;
    display: table;
    width: 100%;
    text-align: center;
    padding: 5px 0;
    text-transform: uppercase;
    font-family: 'BlenderPro';
    font-weight: 800;
    color: #1b1b1b;
  }
  section#home_section .container img.logo {
    display: none;
  }

  section#home_section .bottomBlock {
    bottom: 0;
    position: relative;
    width: 100%;
    left: 0;
  }

  .h3, h1, h2, h3, h4 {
    font-size: 34px;
    margin: 0 0 5px;
  }

  section#home_section .bottomBlock .homeSlickSecond .infoBlock p.mini {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .defaultButton {
    font-size: 16px;
    min-width: 0;
  }

  button.arrows img {
    height: 19px;
  }

  .defaultButton+.arrows {
    margin-left: 10px;
  }

  section .container {
    padding-right: 15px;
    padding-left: 15px;
    top: unset;
    transform: unset;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  p.pre {
    font-size: 20px;
    margin-bottom: 10px;
  }

  button.arrows+.arrows {
    margin-left: 5px;
  }

  section#home_section .homeSlick {
    position: relative;
    width: 100%;
    top: 0;
    right: 0;
    transform: unset;
  }

  section {
    height: auto!important;
    min-height: 0!important;
    position: relative!important;
    display: block!important;
  }

  section#home_section .homeSlick .slick-slide .infoSlide {
    height: 150px;
  }

  section#home_section .boldLineVertical {
    width: 100px;
  }

  section#home_section .container {
    height: auto;
    padding-top: 100px;
    padding-bottom: 40px;
  }

  div#fullpage {
    display: block;
    height: auto;
    transform: unset;
  }

  .fp-tableCell {
    height: auto!important;
  }

  section#company_section .row {
    transform: unset;
    top: unset;
  }

  div#mobileHeader {
    padding-left: 15px;
    padding-right: 15px;
  }

  div#mobileHeader button.burger {
    right: 15px;
  }

  p.descr {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 25px;
  }

  section#company_section .videoBlock button.play img {
    height: 60px;
  }

  section .container .row {transform: unset!important;}

  section.about p.text b {
    font-size: 24px;
  }

  section.about p.text {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 15px;
  }

  section.about p.text.inline {
    font-size: 14px;
    max-width: calc(100% - 80px);
    margin-left: 20px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  section.about p.text span {
    font-size: 18px;
  }

  section.about img.icon {
    width: 50px;
  }

  section#mission_section .container {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }

  section#certs_section .certSlick .slick-slide button img {
    height: 240px;
  }

  section#certs_section .certSlick {
    margin: 20px 0;
  }

  section#certs_section .container {
    text-align: center;
  }

  section#certs_section .certSlick .slick-slide button {
    margin: 0 auto;
  }

  .h3 span, h1 span, h2 span, h3 span, h4 span {
    font-size: 34px;
  }

  .h3 {}

  section#company_section .container img.absolBkg {
    display: none;
  }

  section#company_section {
    background: #1b1b1b;
    border-top: 1px solid #222;
    border-bottom: 1px solid #222;
  }

  section#partners_section ul.partnersList {
    margin: 25px 0 0;
    display: block;
  }

  section#partners_section ul.partnersList li {
    width: 49%;
    display: inline-block;
    margin-bottom: 15px;
  }

  section#partners_section ul.partnersList li img {
    height: 50px;
    margin: 0 auto;
    display: table;
  }

  section#partners_section ul.partnersList+ul.partnersList {
    margin-top: 0;
    margin-bottom: -15px;
  }

  section#about_section .container img.absolBkg {
    display: none;
  }

  section#about_section {
    background: url(/img/bkg3.jpg) no-repeat;
    background-size: cover;
    background-position: center;
  }

  section.productsSection .customProdutsSlider .slick-slide p.afterText {
    font-size: 18px;
    margin-bottom: 15px;
  }

  section.productsSection .customProdutsSlider .slick-slide h3 {
    margin-bottom: 10px;
  }

  section.productsSection .customProdutsSlider .slick-slide img.productImg {
    height: 150px;
    width: 100%;
    object-fit: contain;
    object-position: center;
    margin: 0 0 15px;
  }

  section.productsSection .customProdutsSlider .slick-slide .infoBlock {
    padding: 0;
  }

  section.productsSection .customProdutsSlider .slick-slide .infoBlock p.name {
    font-size: 16px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  section.productsSection .customProdutsSlider .slick-slide .infoBlock p.name span {
    font-size: 16px;
  }

  section.productsSection .customProdutsSlider .slick-slide .infoBlock p.descr, section.productsSection .customProdutsSlider .slick-slide .infoBlock p.textP {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;
  }

  section.productsSection .customProdutsSlider .slick-slide .infoBlock p.yesText img {
    width: 25px;
  }

  section.productsSection .customProdutsSlider .slick-slide .infoBlock p.yesText span {
    font-size: 14px;
    width: calc(100% - 38px);
    margin-left: 10px;
  }

  section.productsSection .customProdutsSlider .slick-slide .infoBlock p.textP span {
    font-size: 16px;
  }

  section.productsSection .customProdutsSlider .slick-slide .infoBlock p.textP+p.textP {
    margin-left: 20px;
  }

  section.productsSection .customProdutsSlider .slick-slide .infoBlock p.textP.full {
    margin-bottom: 0;
  }

  section.productsSection {
    background: #1b1b1b;
    border-top: 1px solid #222;
    border-bottom: 1px solid #222;
  }

  section#projects_section .projectsSlick {
    width: 100%;
    margin: 25px 0;
  }

  section#projects_section .projectsSlick .slick-slide .block {
    height: 200px;
  }

  section#projects_section .projectsSlick .slick-slide .block p.title {
    font-size: 16px;
  }

  section#projects_section .projectsSlick .slick-slide .block .overflow {}

  section#projects_section .projectsSlick:after {
    display: none;
  }

  section#news_section .newsSlick {
    margin: 20px 0;
  }

  section#news_section .newsSlick .slick-slide .block {
    width: 100%;
  }

  section#news_section .newsSlick .slick-slide .block img {
    height: 180px;
    margin-bottom: 18px;
  }

  section#news_section .newsSlick .slick-slide .block p.name {
    font-size: 16px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  section#news_section .newsSlick .slick-slide .block p.descr {
    font-size: 16px;
  }

  section#news_section .newsSlick .slick-slide .block p.date {
    font-size: 18px;
    padding-top: 15px;
    margin-top: 15px;
  }

  section#news_section .newsSlick .slick-slide .block p.date:before {
    width: 12px;
    height: 12px;
    top: -6px;
    margin-left: -6px;
  }

  section#news_section .newsSlick:after {
    bottom: 38px;
    width: 100%;
  }

  section#contacts_section h3 {
    margin-bottom: 25px;
  }

  section#contacts_section p.text {
    width: 100%;
    margin: 25px 0 0;
  }

  section#contacts_section p.text img {
    width: 35px;
    margin: 0 15px 0 0;
  }

  section#contacts_section p.text span {
    font-size: 16px;
    font-weight: 400;
  }

  section#contacts_section .defaultButton {
    font-size: 18px;
    padding: 12px 20px;
    margin: 25px 0 0;
  }

  section#contacts_section {
    border-top: 1px solid rgba(6,6,6,.35);
  }

  section#contacts_section .map {
    position: relative;
    left: 0;
    height: 200px;
    width: 100%;
  }

  section#contacts_section .container {
    padding-bottom: 25px;
  }
  section#home_section.afterAnimation .bottomBlock .homeSlickSecond p.title {
    max-width: 80%;
  }
  .loader{
    z-index: 100;
  }
  section#home_section {
    .container{
      min-height: 493px!important;
    }
  }
  div#mobileHeader ul.menu li a {
    font-weight: 700;
    opacity: 0.9;
  }

  div#mobileHeader ul.menu {
    padding: 10px 15px;
  }
  section#projects_section .projectsSlick .slick-slide .block p.title {
    padding: 10px 20px;
    font-size: 14px;
  }
  section.productsSection .customProdutsSlider .slick-slide img.productImg {
    object-position: left center;
  }
  section.productsSection .customProdutsSlider .slick-slide .infoBlock .bodyText * {
    font-size: 14px;
  }

  section.productsSection .customProdutsSlider .slick-slide .infoBlock .bodyText {
    font-size: 14px;
  }
}